@font-face {
  font-family: 'ICE-Armenia';
  src:  url(/_next/static/media/ICE-Armenia.aca83ffa.eot);
  src:  url(/_next/static/media/ICE-Armenia.aca83ffa.eot#iefix) format('embedded-opentype'),
    url(/_next/static/media/ICE-Armenia.8310a5ec.woff2) format('woff2'),
    url(/_next/static/media/ICE-Armenia.fe42a5ad.ttf) format('truetype'),
    url(/_next/static/media/ICE-Armenia.0640b224.woff) format('woff'),
    url(/_next/static/media/ICE-Armenia.ea48ba5c.svg#ICE-Armenia) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ice-"], [class*=" ice-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ICE-Armenia' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ice-check:before {
  content: "\e90d";
}
.ice-arrow-long:before {
  content: "\e90c";
}
.ice-plus:before {
  content: "\e90a";
}
.ice-minus:before {
  content: "\e90b";
}
.ice-arrow-right:before {
  content: "\e909";
}
.ice-email:before {
  content: "\e903";
}
.ice-fb:before {
  content: "\e904";
}
.ice-linkedin:before {
  content: "\e906";
}
.ice-telegram:before {
  content: "\e907";
}
.ice-youtube:before {
  content: "\e908";
}
.ice-arrow:before {
  content: "\e905";
}
.ice-burger-close:before {
  content: "\e900";
}
.ice-burger:before {
  content: "\e901";
}
.ice-download:before {
  content: "\e902";
}

@font-face {
font-family: '__montserrat_arm_c6d74f';
src: url(/_next/static/media/23dadb42d2cc1eb1-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__montserrat_arm_c6d74f';
src: url(/_next/static/media/c37b649fd4d9ea04-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__montserrat_arm_c6d74f';
src: url(/_next/static/media/b061c86e7eb6706d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__montserrat_arm_c6d74f';
src: url(/_next/static/media/2b305461c8fb2650-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__montserrat_arm_c6d74f';
src: url(/_next/static/media/7e864f962dcd58ee-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__montserrat_arm_c6d74f';
src: url(/_next/static/media/a34c5d6409308828-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__montserrat_arm_c6d74f';
src: url(/_next/static/media/f96d3bc903086e53-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__montserrat_arm_c6d74f';
src: url(/_next/static/media/371b202e8eac0fc3-s.p.woff) format('woff');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__montserrat_arm_c6d74f';
src: url(/_next/static/media/5d484314faab8ccf-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__montserrat_arm_Fallback_c6d74f';src: local("Arial");ascent-override: 85.56%;descent-override: 22.19%;line-gap-override: 0.00%;size-adjust: 113.13%
}.__className_c6d74f {font-family: '__montserrat_arm_c6d74f', '__montserrat_arm_Fallback_c6d74f'
}

