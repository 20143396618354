@font-face {
  font-family: 'ICE-Armenia';
  src:  url('fonts/ICE-Armenia.eot?gzof32');
  src:  url('fonts/ICE-Armenia.eot?gzof32#iefix') format('embedded-opentype'),
    url('fonts/ICE-Armenia.woff2?gzof32') format('woff2'),
    url('fonts/ICE-Armenia.ttf?gzof32') format('truetype'),
    url('fonts/ICE-Armenia.woff?gzof32') format('woff'),
    url('fonts/ICE-Armenia.svg?gzof32#ICE-Armenia') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ice-"], [class*=" ice-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ICE-Armenia' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ice-check:before {
  content: "\e90d";
}
.ice-arrow-long:before {
  content: "\e90c";
}
.ice-plus:before {
  content: "\e90a";
}
.ice-minus:before {
  content: "\e90b";
}
.ice-arrow-right:before {
  content: "\e909";
}
.ice-email:before {
  content: "\e903";
}
.ice-fb:before {
  content: "\e904";
}
.ice-linkedin:before {
  content: "\e906";
}
.ice-telegram:before {
  content: "\e907";
}
.ice-youtube:before {
  content: "\e908";
}
.ice-arrow:before {
  content: "\e905";
}
.ice-burger-close:before {
  content: "\e900";
}
.ice-burger:before {
  content: "\e901";
}
.ice-download:before {
  content: "\e902";
}
